.type {
  //border-radius: 20px;
  //background-color: #F9E6E6;
  //padding: 9px 27px;
  font: {
    size: calculateRem(13);
    weight: 500;
  }
  color: $secondary;
  display: inline-block;
}

.icon-type {
  font-size: 0;
  display: inline-block;
  color: white;
  @include size(21px, 34px);
  background-size: 21px 34px;
  background-repeat: no-repeat;
  background-position: center;
  &--pdf {
    background-image: url("../../img/components/icon--pdf.svg");
  }
}