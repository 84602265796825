.print-order-form {
  @extend %bloc-spacing;
  &__container-button {
    margin-top: 30px;
    button {
      margin-bottom: 20px;
    }
  }
  &__container-image {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    @include media-breakpoint-down(sm) {
        display: none;
    }
  }
}