@keyframes apparition {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate {
  transform: translate(0, 100px);
  opacity: 0;
  //transition-property: opacity;
  //transition-duration: 500ms;
  //transition-timing-function: ease-out;
  //transition-delay: 300ms;
  //will-change: opacity;
  &--animated {
    transform: translate(0, 0);
    opacity: 1;
    animation: apparition 1000ms ease;
  }
}