/*!
 * Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "../../../../node_modules/bootstrap/scss/functions";
@import "bootstrap-variables";

@import "../../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../../../node_modules/bootstrap/scss/mixins/grid-framework";
@import "../../../../node_modules/bootstrap/scss/mixins/grid";

@import "../../../../node_modules/bootstrap/scss/grid";

@import "../../../../node_modules/bootstrap/scss/utilities/display";
@import "../../../../node_modules/bootstrap/scss/utilities/flex";
@import "../../../../node_modules/bootstrap/scss/utilities/spacing";
@import "../../../../node_modules/bootstrap/scss/vendor/rfs";
@import "../../../../node_modules/bootstrap/scss/mixins/reset-text";
@import "../../../../node_modules/bootstrap/scss/mixins/hover";
@import "../../../../node_modules/bootstrap/scss/mixins/alert";
@import "../../../../node_modules/bootstrap/scss/mixins/table-row";
@import "../../../../node_modules/bootstrap/scss/mixins/border-radius";
@import "../../../../node_modules/bootstrap/scss/mixins/gradients";
@import "../../../../node_modules/bootstrap/scss/tables";
@import "../../../../node_modules/bootstrap/scss/alert";
@import "../../../../node_modules/bootstrap/scss/tooltip";