.input {
  appearance: none;
  &[type="text"], &[type="email"], &[type="phone"] {
    border: 2px solid #999999;
    height: 48px;
    width: auto;
    padding: 10px;
    font-size: calculateRem(16);
    &:focus {
      outline: none;
    }
    &:invalid {
      //border-color: $secondary;
    }
  }
  &[type="radio"] {

  }
  &[type="number"] {
    border: 2px solid #999999;
    height: 48px;
    padding: 5px 10px;
    font-size: calculateRem(16);
  }
  &::placeholder {
    opacity: 0;
  }
  &:not(:focus):not(:placeholder-shown):valid {
    // Not focused, not empty, valid value
    border-color: black;
  }
  &:not(:focus):not(:placeholder-shown):invalid {
    // Not focused, not empty, invalid value
    border-color: $secondary;
  }
}