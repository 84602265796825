.print-order-summary {
  padding: 10px 30px 20px;
  background-color: #F4F4F4;
  height: min-content;
  margin-bottom: 40px;
  &__title {
    text-align: right;
    border-bottom: 1px solid #E0E0E0;
    @include media-breakpoint-down(md) {
        border: none;
    }
  }
  &__item {
    padding: 16px 0;
    border-bottom: 1px solid #E0E0E0;
    &:last-child {
      margin-bottom: 38px;
    }
    &:first-child {
      padding-top: 0;
    }
  }
  &__name {
    display: block;
    margin-bottom: 5px;
    font-size: 0.9rem;
  }
  &__description {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    flex-wrap: wrap;
    span {
      margin-bottom: 5px;
      font-size: calculateRem(14);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__name-delete {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
  }
  &__price, &__copies {
    display: flex;
    flex-flow: row;
    span {
      display: block;
      width: 70px;
      text-align: right;
    }
  }
  &__label {
    font-weight: bold;
  }
  &__total {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    margin-top: 40px;
    &-TTC {
      font-size: calculateRem(18);
      font-weight: 400;
    }
    &-fee {
      font-size: calculateRem(22);
      font-weight: 500;
    }
  }
  &__fee {
    margin-bottom: 15px;
    padding-bottom: 15px;
    width: 90%;
    text-align: right;
    border-bottom: 1px solid black;
    @extend .print-order-summary__total-TTC;
  }
}