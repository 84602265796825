.table {
  &__thead {
    .thead__td {
      border: none;
    }
    @include media-breakpoint-down(md) {
        display: none;
    }
  }
  &__tr {
    height: 59px;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 500ms;
    &--variant {
      @extend .table__tr;
      height: 37px;
      &:nth-child(odd) {
        background-color: $fifth;
      }
    }
    &--delete {
      opacity: 0;
    }
    @include media-breakpoint-down(md) {
      display: block;
      height: auto;
      margin-bottom: 30px;
    }
  }
  &__td {
    font-size: calculateRem(14);
    vertical-align: middle !important;
    position: relative;
    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 55px;
      &--delete {
        justify-content: center;
      }
      &:before {
        content: attr(data-label);
      }
    }
    &--variant {
      @extend .table__td;
      border: none !important;
      padding: 3px 0.75rem !important;
      text-align: center;
      font-size: calculateRem(17);
      &:first-child {
        text-align: left;
        @include media-breakpoint-up(lg) {
          width: 390px;
        }
        @include media-breakpoint-down(md) {
          justify-content: flex-start;
          font-weight: 700;
          &:before {
            margin-right: 5px;
          }
        }
      }
      @include media-breakpoint-down(md) {
          height: auto;
      }
    }
    &--type {
      background-image: url("../../img/components/icon--pdf.svg");
      background-size: 21px 34px;
      background-repeat: no-repeat;
      background-position: left;
      padding-left: 35px !important;
      max-width: 180px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      @include media-breakpoint-down(md) {
        max-width: initial;
        background: none;
        padding-left: 0.75rem !important;
      }
    }
  }
}

.thead {
  &__tooltip {
    display: flex;
    span {
      flex: 1;
    }
  }
  &--variant {
    .table__tr {
      &--variant {
        background-color: white;
      }
    }
  }
  &__td {
    font-size: calculateRem(11);
    text-transform: uppercase;
    &--variant {
      @extend .thead__td;
      color: $sixth;
    }
  }
}