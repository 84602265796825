// Colors
// -----------------------------
$primary: #000000;
$secondary: #FF4D3D;
$tertiary: #F9E6E6;
$quartenary: #262626;
$fifth: #F8F8F8;
$sixth: #999999;

// Sizes
// -----------------------------
$maxWidth: 980px;

// Menu height
// -----------------------------
$menuSizeHeight: 55;
$menuMarginBottom: 49;
$menuSizeHeightResponsive: 55;
$paddingMenu: 20;
$gutter: 70;
$gutterResponsive: 15;

$navResponsive: false;
$languages: false;
/*
  1: nav + responsiveNav all size
  2: nav
  3: responsiveNav
  4: nav (md >) + responsiveNav (< md) FIXME not effective yet
*/
$typeOfNav: 2;

// ScrollBar Color
// -----------------------------
$barColor: #fafafa;
$scroll: #666666;
