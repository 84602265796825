* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  font-family: $font;
}

html {
  min-height: 100%;
  font-size: 100%;
  line-height: 1.5;

  @include media-breakpoint-only(lg) {
    font-size: 94%;
  }
  @include media-breakpoint-only(md) {
    font-size: 84%;
  }
  @include media-breakpoint-only(sm) {
    font-size: 68%;
  }
  @include media-breakpoint-only(xs) {
    font-size: 84%;
  }
}

body {
  background-color: $backgroundColor;
  color: $fontColor;
  margin: 0;
  min-height: 100%;
  position: relative;

  font: {
    family: $font;
    size: calculateRem(16);
    weight: 400;
  }
}

::selection{ color:$backgroundColor; background:$fontColor; }

a {
  color: $primary;
  opacity: 1;
  text-decoration: none;
  transition-property: color;
  transition-duration: .3s;
  &:hover {
    color: $secondary;
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
}

p {
  line-height: calculateRem(28);
}

button {
  background: none;
  cursor: pointer;
  box-sizing: content-box;
  border: none;
  &:focus {
    outline: none;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background-color: $barColor;
}

*::-webkit-scrollbar-thumb {
  background-color: $scroll;
}

.container-fluid {
  padding: 0 #{$gutterResponsive}px;
  @include media-breakpoint-up(md) {
    padding: 0 #{$gutter}px;
  }
}

.cta {
  border-radius: 30px;
  padding: 15px 35px;
  font-size: calculateRem(17);
  cursor: pointer;
  color: $secondary;
  &--white {
    background-color: white;
  }
  &--pink {
    background-color: $tertiary;
  }
  &--grey {
    background-color: $fifth;
    color: black;
    .pink {
      color: $secondary;
    }
  }
}

.img {
  max-width: 100%;
  width: 100%;
  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }
  &--marginTop {
    margin-top: 150px;
    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
  }
  &--marginBottom {
    @include media-breakpoint-down(md) {
      margin-bottom: 50px;
    }
  }
}

.linkWithArrow {
  display: flex;
  align-items: center;
  text-decoration: underline;
  &:before, &:after {
    display: inline-block;
    width: 10px;
    height: 6px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("/img/components/backTop.svg");
  }
  &--left:before {
    margin-right: 5px;
    content: '';
    transform: rotate(-90deg);
  }
  &--right:after {
    margin-left: 5px;
    content: '';
    transform: rotate(90deg);
  }
}

.responsiveDisplay {
  display: none;
  @include media-breakpoint-down(sm) {
      display: block;
  }
}

.title-h1 {
  font: {
    size: calculateRem(70);
    weight: 400;
  }
  color: $secondary;
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 1;
  @include media-breakpoint-down(md) {
    font-size: calculateRem(50);
  }
}

.title-h2 {
  font: {
    size: calculateRem(36);
    weight: 400;
  }
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 724px;
  @include media-breakpoint-down(md) {
      font-size: calculateRem(26);
  }
}

.title-h3 {
  font: {
    size: calculateRem(22);
    weight: 400;
  }
  margin-top: 0;
  margin-bottom: 22px;
  padding-bottom: 22px;
}

.container-img {
  @include media-breakpoint-down(sm) {
      display: none;
  }
}