.form {
  padding: 20px;
  background-color: $fifth;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__fieldset {
    margin: 0 0 21px;
    display: flex;
    flex-flow: column;
    width: 100%;
    @include media-breakpoint-down(md) {
      width: 100% !important;
    }
    &--xl {
      @extend .form__fieldset;
      width: calc(80% - 10px);
    }
    &--lg {
      @extend .form__fieldset;
      width: calc(60% - 10px);
    }
    &--md {
      @extend .form__fieldset;
      width: calc(50% - 10px);
    }
    &--sm {
      @extend .form__fieldset;
      width: calc(33% - 10px);
    }
    &--xs {
      @extend .form__fieldset;
      width: calc(20% - 10px);
    }
    &--buttons {
      @extend .form__fieldset;
      justify-content: flex-end;
      flex-flow: row;
      button {
        margin-left: 10px;
      }
    }
    &--row {
      @extend .form__fieldset;
      flex-flow: row;
      align-items: center;
      label {
        margin-bottom: 0;
        order: 2;
        margin-left: 10px;
        a {
          text-decoration: underline;
        }
      }
    }
  }
  &__label {
    display: block;
    margin-bottom: 7px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}