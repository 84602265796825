.tag {
  margin-right: 5px;
  margin-bottom: 5px;
  outline: none;
  &:after {
    content: attr(data-label);
    border-radius: 20px;
    background-color: $sixth;
    color: white;
    display: inline-flex;
    cursor: pointer;
    //padding: 5px 20px;
    padding: 0.5em 1em;
    font: {
      size: calculateRem(12);
      weight: 500;
    }
  }
  &:checked {
    &:after {
      background-color: $secondary;
    }
  }
  &:last-child {
    margin-right: 0;
  }
}