.button {
  background-color: $secondary;
  color: white;
  font: {
    size: calculateRem(17);
    weight: bold;
  }
  border: 1px solid $secondary;
  padding: calc(1rem - 2px) calc(2rem - 2px);
  border-radius: 30px;
  width: initial;
  display: inline-block;
  transition-property: background-color, color;
  transition-duration: 300ms;
  &:hover {
    background-color: white;
    color: $sixth;
    border-color: $sixth;
  }
  &--invert {
    background-color: white;
    color: $sixth;
    border-color: $sixth;
    margin-right: calculateRem(22);
    &:hover {
      background-color: $secondary;
      color: white;
      border-color: $secondary;
    }
  }
  &--delete {
    font-size: 0;
    @include size(11px, 14px);
    background-image: url("../../img/components/delete.svg");
    background-repeat: no-repeat;
    background-size: 11px 14px;
    background-position: center;
    background-color: white;
    padding: 0;
    border-radius: 0;
    border: none;
    &:hover {
      background-color: white;
    }
    @include media-breakpoint-down(md) {
      font-size: calculateRem(16);
      background-image: none;
      color: white;
      @include size(auto);
      background-color: $secondary;
      //padding: 6px 16px;
      padding: 0.5em 1em;
      border-radius: 30px;
      width: max-content;
      display: inline-block;
    }
  }
  &--tooltip {
    font-size: 0;
    @include size(14px);
    background-image: url("../../img/components/tooltip.svg");
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: center;
    background-color: white;
    padding: 0;
    border-radius: 0;
    border: none;
    cursor: default;
    //display: block;
    margin-left: 5px;
  }
  &--plus {
    font-size: 0;
    @include size(22px, 22px);
    background-image: url("/img/components/plus--black.svg");
    padding: 0;
    background-repeat: no-repeat;
    background-size: 9px;
    background-position: center;
    background-color: #D8D8D8;
    margin-top: 2px;
  }
  &--dropdown {
    background-color: $secondary;
    color: white;
    position: relative;
    padding-right: 26px;
    &:after {
      content: '';
      @include size(6px);
      display: block;
      background: linear-gradient(135deg, $secondary 50%, white 50%);
      transform: rotate(45deg) translate(-110%);
      position: absolute;
      top: 50%;
      right: 7px;
    }
  }
}