.checkbox {
  @include size(18px);
  border: 1px solid $sixth;
  background-color: white;
  background-position: center;
  background-size: 14px 10px;
  background-repeat: no-repeat;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  &:checked {
    background-image: url("../../img/components/checked.svg");
    background-color: $secondary;
    border-color: $secondary;
  }
}