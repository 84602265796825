.dropdown {
  width: max-content;
  position: relative;
  &__form {
    background-color: $fifth;
    padding: 13px 22px;
    width: max-content;
    position: absolute;
    right: 0;
    z-index: 10;
    top: 37px;
    display: none;
    &--active {
      display: block;
    }
  }
}