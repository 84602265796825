%bloc-spacing {
  padding: 0px 0 150px 0;
  @include media-breakpoint-down(xs) {
    padding: 50px 0;
  }
}

%cta {
  padding: 16px 30px;
  background-color: $quartenary;
  color: white;
  display: inline-flex;
  align-items: center;
  border: 1px solid $white;
  transition-property: color, background-color;
  transition-duration: .3s;
  font: {
    size: calculateRem(14);
    weight: bold;
  };
  letter-spacing: 1px;
  border-radius: 26px;
  &:hover {
    background-color: $white;
    color: $quartenary;
    border: 1px solid $quartenary;
  }
}

%cta--white {
  @extend %cta;
  background-color: white;
  color: $quartenary;
  border-color: $quartenary;
  &:hover {
    background-color: $quartenary;
    color: white;
    border-color: white;
  }
}

%title1 {
  font: {
    size: calculateRem(70);
    weight: 400;
  }
  color: $secondary;
  margin-top: 0;
  margin-bottom: 15px;
}

%title2 {
  font: {
    size: calculateRem(36);
    weight: 400;
  }
  margin-top: 0;
  margin-bottom: 46px;
}

%title3 {
  font: {
    size: calculateRem(16);
    weight: 500;
  }
  line-height: calculateRem(30);
  margin-top: 0;
  margin-bottom: 13px;
}