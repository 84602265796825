.header {
  width: 100%;
  padding: #{$paddingMenu}px 0;
  left: 0;
  height: #{$menuSizeHeightResponsive+($paddingMenu)}px;
  display: flex;
  align-items: center;
  position: relative;
  &:before {
    content: '';
    width: 382px;
    height: 382px;
    background-color: #f9e6e6;
    position: absolute;
    border-radius: 382px;
    left: -222px;
    top: -67px;
    z-index: -1;
  }
  @include media-breakpoint-up(md) {
    height: #{$menuSizeHeight+($paddingMenu*2)}px;
  }
  @include media-breakpoint-down(sm) {
    padding: #{$paddingMenu/2}px 0;
  }
  &__container {
    padding: 0 #{$gutterResponsive}px;
    display: flex;
    width: 100%;
    @include media-breakpoint-up(md) {
      padding: 0 #{$gutter}px;
    }
  }
}