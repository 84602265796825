.progress {
  position: relative;
  width: 150px;
  &__bar {
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: $sixth;
    top: 50%;
    transform: translateY(-50%);
    &--red {
      background-color: $secondary;
      width: 0%;
    }
  }
  &__percent {
    font-size: calculateRem(14);
    color: $secondary;
    position: absolute;
    top: -20px;
    left: 0%;
    transform: translateX(-50%);
  }
  &__delete {
    position: absolute;
    right: -30px;
    top: -9px;
  }
}