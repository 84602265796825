@mixin fontSize($size){
  font-size: #{$size + px} ;
  font-size: calculateRem($size);
}

@mixin google-font($family){
  @import url("https://fonts.googleapis.com/css?family=#{$family}");
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin padding($top, $right: $top, $bottom: $top, $left: $right) {
  padding: calculateEM($top) calculateEM($right) calculateEM($bottom) calculateEM($left);
}