.select {
  border: 2px solid #999999;
  height: 48px;
  padding: 4px 70px 4px 12px;
  border-radius: 0;
  background-color: white;
  font-size: calculateRem(16);
  outline: none;
  background-image: url("../../img/components/dropdown-grey.svg");
  background-repeat: no-repeat;
  background-size: 8px 5px;
  background-position: calc(100% - 26px) center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}