.link {
  font-size: calculateRem(14);
  line-height: calculateRem(20);
  text-decoration: underline;
  color: black;
  margin-bottom: 4px;
  &--red {
    color: $secondary;
    margin-bottom: 0;
  }
  &:visited {
    text-decoration: underline;
  }
  &--underline {
    text-decoration: underline;
  }
}