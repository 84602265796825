.breadcrumb {
  margin-bottom: 60px;
  &__ul {
    display: flex;
    margin-bottom: 16px;
    @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
    }
  }
  &__li {
    color: $sixth;
    text-transform: uppercase;
    font-size: calculateRem(14);
    width: calc(100% / 3);
    text-align: center;
    &--active {
      color: black;
    }
    @include media-breakpoint-down(xs) {
        width: 100%;
    }
  }
  &__container-lines {
    width: 100%;
    height: 2px;
    background-color: $sixth;
    position: relative;
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
  &__line {
    display: block;
    width: calc(100% / 3);
    height: 2px;
    position: absolute;
    background-color: black;
    top: 0;
  }
}