/*
  Fonts
*/

@mixin font-face($style-name, $file, $weight, $style: normal) {
  $filepath: '../../fonts/' + $file;
  @font-face {
    font-family: $style-name;
    src: url($filepath + ".ttf") format('truetype'), url($filepath + ".woff") format('woff');
    font-weight: $weight;
    font-style: $style;
  }
}

$font: 'Aeonik';
$font2: 'TiemposHeadline';

@include font-face($font, $font + "-Light", 300, "normal");
@include font-face($font, $font + "-Regular", 400, "normal");
@include font-face($font, $font + "-Medium", 500, "normal");
@include font-face($font, $font + "-Bold", 700, "normal");
@include font-face($font2, $font2 + "-Regular", 400, "normal");

$font: unquote($font) + ', sans-serif';
$font2: unquote($font2) + ', sans-serif';
$fontColor: $primary !default;
$linkColor: $primary;
$backgroundColor: white; //invert($font2-color) !default;
$fontSize: 12 !default;
$fontH1: 40 !default;
$fontDeltaH: 8 !default;
