@function calculateRem($size) {
  $remSize: $size / 16;

  @return #{$remSize + rem};
}

@function calculateEM($size) {
  $emSize: $size / 16;

  @return #{$emSize + em};
}