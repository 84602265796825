.message {
  color: $secondary;
  font-weight: 500;
  line-height: calculateRem(23);
  padding: 22px 21px;
  background-color: #F9E6E6;
  display: inline-block;
  width: max-content;
  margin-bottom: 20px;
  @include media-breakpoint-down(xs) {
      width: 100%;
  }
}

.text {
  font-size: calculateRem(22);
  display: block;
  margin-bottom: 70px;
  @include media-breakpoint-down(xs) {
    margin-bottom: 30px;
  }
  &--red {
    color: $secondary;
  }
}