@charset "UTF-8";

$menuAnimate: 1;
@import 'basics/bootstrap';

@import 'basics/variables';
@import 'basics/functions';
@import 'basics/mixins';
@import 'basics/fonts';
@import 'basics/placeholders';

@import 'basics/base';

@import "components/header";
@import "atoms/logo";
@import 'components/footer';

@import "molecules/form";
@import "atoms/buttons";
@import "atoms/message";
@import "atoms/link";
@import "atoms/type";
@import "atoms/checkbox";
@import "atoms/tag";
@import "atoms/input";
@import "atoms/select";
@import "molecules/table";
@import "molecules/dropdown";
@import "molecules/progress";
@import "molecules/breacrumb";

@import 'components/upload';
@import 'components/print-order-form';
@import 'components/overview';
@import 'components/print-order-summary';
@import 'components/checkout';
@import 'components/prices';

@import "basics/animations";