.footer {
  padding: 150px 0 50px 0;
  //background-image: url("/img/components/logoFooter.svg");
  background-repeat: no-repeat;
  background-position: -400px 0;
  background-size: 1200px;
  background-color: $fifth;
  @include media-breakpoint-down(xs) {
    padding: 50px 30px 30px 30px;
    background: none;
  }
  &__title-informations {
    flex-flow: row wrap;
    position: relative;
  }
  &__logo {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    @include media-breakpoint-down(sm) {
        margin: 0 auto 30px auto;
    }
  }
  &__h2 {
    font-size: calculateRem(14);
    line-height: calculateRem(27);
    letter-spacing: 0.78px;
    color: $secondary;
    margin-top: 0;
    margin-bottom: 10px;
  }
  &__paragraph {
    font: {
      size: calculateRem(12);
      weight: 400;
    }
    line-height: calculateRem(20);
    margin: 0 0 20px;
    max-width: 250px;
    &:last-child {
      margin-bottom: 40px;
    }
    @include media-breakpoint-down(sm) {
      margin: auto auto 45px;
    }
  }
  &__important-title {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
  &__back-top {
    display: block;
    position: absolute;
    bottom: 10px;
    right: 0;
    text-indent: -5000px;
    font-size: 0;
    //background-image: url("/img/components/new_arrow.svg");
    background-position: center;
    background-size: 17px 20px;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    width: 17px;
    height: 20px;
  }
  &__container-btn {
    margin-bottom: 150px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 45px;
    }
    @include media-breakpoint-down(xs) {
      margin-left: 0;
      display: flex;
      justify-content: center;
    }
  }
  &__cta {
    @extend %cta;
  }
}

.informations {
  position: relative;
  padding-bottom: 50px;
  @include media-breakpoint-down(md) {
      padding-left: 30px;
  }
  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding-left: 15px;
    text-align: center;
    padding-bottom: 30px;
  }
  &__h3 {
    font: {
      size: calculateRem(16);
      weight: 400;
    }
    margin-bottom: 30px;
    margin-top: 0;
    text-transform: initial;
    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      margin-bottom: 11px;
    }
    &:after {
      content: '';
      display: block;
      height: 7px;
      width: 5px;
      background-color: $secondary;
      margin-top: 11px;
    }
  }
  &__list-item {
    flex-flow: row;
  }
  &__item {
    display: block;
    //line-height: 1.79;
    width: auto;
  }
  &__title-contact {
    display: block;
    font: {
      size: calculateRem(12);
      weight: bold;
    }
    text-transform: uppercase;
    color: $primary;
  }
  &__link-contact {
    font-size: calculateRem(14);
    line-height: calculateRem(18);
    color: $primary;
    display: inline-block;
  }
}

.social-network {
  display: flex;
  @include media-breakpoint-down(md) {
    justify-content: center;

  }
  &__icon {
    display: inline-block;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    font-size: 1px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    &--facebook {
      background-image: url("../../img/components/facebook.svg");
    }
    &--twitter {
      background-image: url("../../img/components/twitter.svg");
    }
    &--youtube {
      background-image: url("../../img/components/youtube.svg");
    }
  }
}

.privacy-CGU {
  border-top: 1px solid $tertiary;
  padding-top: 20px;
  display: flex;
  &__align-right {
    text-align: right;
    @include media-breakpoint-down(sm) {
      text-align: center;
      margin-top: 0;
    }
  }
  &__item {
    @include media-breakpoint-down(sm) {
        text-align: center;
    }
  }
  &__link-bottom, &__text-bottom {
    font: {
      size: calculateRem(11);
    }
    color: $primary;
  }
  &__link-bottom {
    text-transform: uppercase;
    margin: 0 3px;
  }
}
