.upload {
  @extend %bloc-spacing;
  &__title-h3 {
    font-size: calculateRem(17);
    color: $secondary;
    margin-bottom: calculateRem(42);
  }
  &__drop-zone-container {
    width: 100%;
  }
  &__drop-zone {
    margin-bottom: calculateRem(40);
    display: inline-flex;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  &__drag-drop {
    border-radius: 7px;
    border: 1px dashed $secondary;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    @include size(750px, 230px);
    max-width: 100%;
    @include media-breakpoint-down(md) {
        width: 100%;
    }
    @include media-breakpoint-down(sm) {
        display: none;
    }
    &--responsive {
      display: none;
      @include media-breakpoint-down(sm) {
        display: flex;
      }
    }
  }
  &__text {
    background-image: url("../../img/components/upload.svg");
    background-position: center top;
    background-size: 75px;
    background-repeat: no-repeat;
    font-size: calculateRem(18);
    line-height: calculateRem(27);
    padding-top: 70px;
    text-align: center;
    min-width: 75px;
    &--red {
      color: $secondary;
    }
  }
  &__uploading {
    width: 100%;
    margin-bottom: 30px;
  }
  &__ul--head {
    width: 100%;
    display: flex;
  }
  &__li {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 10px;
    flex-wrap: wrap;
    border-bottom: 1px solid $sixth;
  }
  &__type, &__name, &__date, &__page, &__color {
    margin-right: 20px;
  }
  &__name, &__date, &__page, &__color {
    font-size: calculateRem(14);
  }
  &__finish {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin: 5px 0;
  }
  &__valid {
    display: block;
    @include size(17px, 12px);
    background-image: url("../../img/components/valid.svg");
    background-repeat: no-repeat;
    background-size: 17px 12px;
    background-position: center;
    margin-left: 20px;
  }
  &__delete {
    margin-left: 20px;
  }
  &__content-button {
    display: flex;
  }
  &__finishing {
    max-width: 147px;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    color: white;
    padding: 8px 30px 8px 16px;
    background-image: url("../../img/components/dropdown.svg");
    background-repeat: no-repeat;
    background-size: 8px 5px;
    background-position: 127px 13px;
    background-color: $secondary;
    border-radius: 30px;
    font-weight: bold;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  &__select-color, &__paper-type {
    max-width: 166px;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    color: white;
    padding: 8px 30px 8px 16px;
    background-image: url("../../img/components/dropdown.svg");
    background-repeat: no-repeat;
    background-size: 8px 5px;
    background-position: 140px 13px;
    background-color: $secondary;
    border-radius: 30px;
    font-weight: bold;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  &__select-color {
    width: 83px;
    background-position: 63px 13px;
    margin-right: 30px;
  }
  &__color, &__page {
    width: 100px;
  }
  &__color-checkbox, &__recto-verso {
    border: none;
    padding: 0;
    margin: 0 20px 0 0;
    outline: none;
    display: flex;
    flex-flow: row;
    align-items: center;
    width: 100px;
    label {
      order: 2;
      margin: 0 0 0 10px;
      font-size: calculateRem(14);
    }
    input {
      order: 1;
    }
  }
  &__recto-verso {
    width: auto;
  }
}