.overview {
  @extend %bloc-spacing;
  &__ul {
    @extend .overview__paragraph;
  }
  &__li {
    position: relative;
    padding-left: 11px;
    &:before {
      display: block;
      content: '';
      background-color: $secondary;
      width: calculateRem(5);
      height: calculateRem(6);
      position: absolute;
      top: calculateRem(10);
      left: 0;
    }
  }
  &__paragraph {
    font-size: calculateRem(17);
    margin-bottom: calculateRem(25);
  }
  &__alert {
    font-size: calculateRem(17);
    margin-bottom: calculateRem(32);
    color: $secondary;
    display: block;
    &:last-of-type {
      margin-bottom: calculateRem(65);
    }
  }
  &__container-button {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
  }
  &__button {
    margin-bottom: 10px;
    &--ml {
      margin-left: 10px;
      @include media-breakpoint-down(xs) {
        margin-left: 0;
      }
    }
  }
}